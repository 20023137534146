import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './routes/routes';

import 'bootstrap/dist/css/bootstrap.css';
import 'slick-carousel/slick/slick.css';

import 'swiper/css';
import 'swiper/css/effect-cards';

import './App.css';

const App = () => {
	return (
		<BrowserRouter>
			<AppRoutes />
		</BrowserRouter>
	);
};

export default App;