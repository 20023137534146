import React from 'react';
import Slider from 'react-slick';
import { SERVICES } from '../../constants/common';

const ServicesSlider = () => {
	const settings = {
		infinite: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		dots: true,
		autoplay: false,
		autoplaySpeed: 0,
		responsive: [
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
				},
			},
		],
	};

	return (
		<div className="horde--roadmap-section">
			<div className="container">
				<div className="horde--section-title">
					<div className="horde--default-content content-sm">
						<h2>Studio Services</h2>
					</div>
				</div>
				<div className="row">
					<Slider {...settings}>
						{SERVICES.map((service, idx) => (
							<div key={idx} className="col-xl-4 col-md-8">
								<div className="horde--iconbox-wrap wow fadeInUpX" data-wow-delay="0s">
									<div className="horde--iconbox-thumb">
										<img src={service.image} alt="" />
									</div>
									<div className="horde--iconbox-data">
										<h4>{service.title}</h4>
										<p>{service.description}</p>
									</div>
								</div>
							</div>
						))}
					</Slider>
				</div>
			</div>
		</div>
	);
};

export default ServicesSlider;