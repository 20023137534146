import React from 'react';
import { SERVICES } from '../../constants/common';

const ServicesFlat = () => {
	return (
		<div className="horde--roadmap-section">
			<div className="container">
				<div className="horde--section-title">
					<div className="horde--default-content content-sm">
						<h2>Studio Services</h2>
					</div>
				</div>
				<div className="row services-row">
					{SERVICES.map((service, idx) => (
						<div key={idx} className="col-xl-4 col-md-8">
							<div className="horde--iconbox-wrap wow fadeInUpX" data-wow-delay="0s">
								<div className="horde--iconbox-thumb">
									<img src={service.image} alt="" />
								</div>
								<div className="horde--iconbox-data">
									<h4>{service.title}</h4>
									<p>{service.description}</p>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default ServicesFlat;
