export const SOCIAL_LINKS = {
	instagram: 'https://www.instagram.com/horde.echo',
	spotify: 'https://open.spotify.com/playlist/14l3iMATCpOr6zrEjzi8IU?si=67ec0f4bd80b4cd5',
	soundcloud: 'https://soundcloud.com/hordeecho'
};

export const CONTACTS = {
	mail: 'hordeecho@gmail.com',
	telegram: '@hordeecho',
	whatsapp: '@hordeecho',
};

export const SERVICES = [
	{
		image: '/images/services/discogs.svg',
		title: 'SOUND PRODUCING',
		description: 'Adding special effects, synths, orchestral arrangement, that can be customized to suit your artistic vision and the context of your track.',
	},
	{
		image: '/images/services/mixer.svg',
		title: 'SOUND MIXING',
		description: 'Bringing together all audio tracks, editing and tuning, guitar and bass reamping, adjusting levels, humanizing MIDI, applying audio effects.',
	},

	{
		image: '/images/services/headphones.svg',
		title: 'SOUND MASTERING',
		description: 'Applying the finishing touches to make your music sound amazing on any device or sound system.',
	},
];

export const TEAM = [
	{
		image: '/images/about/founder.png',
		alt_text: 'Caricature of studio founder',
		title: 'Studio Founder',
		name: 'Viktor',
		job: 'Sound Engineer',
		social_title: 'horde.echo',
		social_link: SOCIAL_LINKS.instagram,
		code: 'founder'
	},
	{
		image: '/images/about/technician.png',
		alt_text: 'Caricature of founder\'s cat',
		title: 'Team Member',
		name: 'Lucy',
		job: 'Technician',
		social_title: null,
		social_link: null,
		code: 'lucy'
	},
];

export const PRICES = [
	{
		title: 'Single',
		description: null,
		popular: false,
		prices: {
			advanced: '140'
		},
		songs_count: '1',
		revisions: {
			advanced: '&infin;'
		},
		delivery_time: {
			advanced: '7-14 days'
		},
	},
	{
		title: 'EP',
		description: null,
		popular: true,
		prices: {
			advanced: '120'
		},
		songs_count: '2-6',
		revisions: {
			advanced: '&#8734;'
		},
		delivery_time: {
			advanced: '2-4 weeks'
		},
	},
	{
		title: 'LP',
		description: '(Album)',
		popular: false,
		prices: {
			advanced: '120'
		},
		songs_count: '6+',
		revisions: {
			advanced: '&infin;'
		},
		delivery_time: {
			advanced: '3-6 weeks'
		},
	}
];