import emailjs from '@emailjs/browser';
import React, { useState, useEffect } from 'react';
import { EMAIL_CONFIG } from '../../constants/config';
import { PAGE_ROUTES } from '../../routes/routes';
import { SOCIAL_LINKS } from '../../constants/common';
import useScrollToHash from '../../hooks/useScrollToHash';

const FORM_ID = 'bookingFormData';

const Booking = () => {
	const [formData, setFormData] = useState({
		from_name: '',
		band_name: '',
		from_email: '',
		message: '',
	});

	const [success, setSuccess] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const savedData = localStorage.getItem(FORM_ID);
		if (savedData) {
			setFormData(JSON.parse(savedData));
		}
	}, []);

	const handleChange = (e) => {
		const { name, value } = e.target;
		const updatedFormData = { ...formData, [name]: value };
		setFormData(updatedFormData);
		localStorage.setItem(FORM_ID, JSON.stringify(updatedFormData));
	};

	const clearForm = () => {
		setFormData({
			from_name: '',
			band_name: '',
			from_email: '',
			message: '',
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		
		setIsLoading(true);

		emailjs.send(EMAIL_CONFIG.SERVICE_ID, EMAIL_CONFIG.TEMPLATE_ID, formData, {
			publicKey: EMAIL_CONFIG.PUBLIC_KEY,
		})
		.then((response) => {
			setIsLoading(false);
			setSuccess(true);
			clearForm();
			localStorage.removeItem(FORM_ID);
		})
		.catch((err) => {
			console.log('FAILED...', err);
			setIsLoading(false);
			setSuccess(false);
		});
	};

	const bookingRef = useScrollToHash('booking');

	return (
		<div ref={bookingRef} id="booking" className="horde--faq-section horde--section-padding">
			<div className="container">
				<div className="horde-section-title booking-title">
					<h2>Book Your Date</h2>
					<p className='mt-3'>Be sure to check out <a href={PAGE_ROUTES.REQS}>file requirements page</a></p>
				</div>
				<div className="row">
					<div className="col-lg-8 offset-lg-2">
						<div className="horde--contact-form wow fadeInUpX" data-wow-delay="0.15s">
							{isLoading && <p>Sending...</p>}
							{!isLoading && success && (
								<>
									<p>Sent! I will be in touch shortly. Please check that your mailbox is not full.</p>
									<p>In case you don't get an answer from me during 24 hours, you can DM in <a href={SOCIAL_LINKS.instagram} target="_blank" rel="noreferrer" className='instagram-link'>Instagram</a></p>
								</>
							)}
							{!isLoading && !success && (
								<form id='booking-form' onSubmit={handleSubmit} autoComplete="off">
									<div className="horde--comment-field">
										<input
											type="text"
											name="from_name"
											id="from_name"
											placeholder="Your Name*"
											value={formData.from_name}
											onChange={handleChange}
											autoComplete="off"
										/>
									</div>
									<div className="horde--comment-field">
										<input
											type="text"
											name="band_name"
											id="band_name"
											placeholder="Band / Musician Name"
											value={formData.band_name}
											onChange={handleChange}
											autoComplete="off"
										/>
									</div>
									<div className="horde--comment-field">
										<input
											type="email"
											name="from_email"
											id="email"
											placeholder="Your Email*"
											value={formData.from_email}
											onChange={handleChange}
											autoComplete="off"
										/>
									</div>
									<div className="horde--comment-field">
										<textarea
											rows="30"
											name="message"
											id="message"
											placeholder="Any details about your project"
											value={formData.message}
											onChange={handleChange}
											autoComplete="off"
										></textarea>
									</div>
									<div className="booking-form-btn">
										<button id="horde--form-submit-btn" type="submit" disabled={formData.from_name === '' || formData.from_email === ''}>
											Send
										</button>
									</div>
								</form>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Booking;
