import React, { useState, useEffect } from 'react';
import useMobile from '../../hooks/useMobile';
import useTablet from '../../hooks/useTablet';
import useScrollToHash from '../../hooks/useScrollToHash';
import { musicData } from '../../constants/music';
import { SOCIAL_LINKS } from '../../constants/common';
import { PAGE_ROUTES } from '../../routes/routes';

const Portfolio = ({isPage = false}) => {
	const isMobile = useMobile();
	const isTablet = useTablet();

	const portfolioRef = useScrollToHash('portfolio');
	
	const [lastPlayedMusic, setLastPlayedMusic] = useState(0);
	const [currentMusic, setCurrentMusic] = useState(0);
	const [audioSource, setAudioSource] = useState(null);

	const [volume, setVolume] = useState(0.3);
	const [muted, setMuted] = useState(false);
	const [isPlaying, setIsPlaying] = useState(false);

	useEffect(() => {
		if (audioSource) {
			audioSource.src = musicData[currentMusic].musicPath;

			playMusic();
		} else {
			const newAudioSource = new Audio(musicData[currentMusic].musicPath);
			setAudioSource(newAudioSource);
		}
	}, [currentMusic, audioSource]);

	useEffect(() => {
		if (audioSource) {
			if (muted) {
				audioSource.muted = true;
			} else {
				audioSource.muted = false;
				audioSource.volume = volume;
			}
		}
	}, [muted, audioSource, volume]);

	useEffect(() => {
		if (audioSource) {
			if (isPlaying) {
				audioSource.play();				
				togglePlay(true);
			} else {
				audioSource.pause();
				togglePlay(false);
			}
		}
	}, [isPlaying, audioSource]);

	useEffect(() => {
		if (audioSource) {
			const playerSeekRange = document.querySelector("[data-seek]");

			const playerRunningTime = document.querySelector("[data-running-time");
			const playerDuration = document.querySelector("[data-duration]");	

			const updateDuration = () => {
				playerSeekRange.max = Math.ceil(audioSource.duration);
				playerDuration.textContent = getTimecode(Number(playerSeekRange.max));
			};
			audioSource.addEventListener("loadeddata", updateDuration);

			const seek = () => {
				audioSource.currentTime = playerSeekRange.value;
				playerRunningTime.textContent = getTimecode(playerSeekRange.value);
			};
			playerSeekRange.addEventListener("input", seek);

			playerSeekRange.addEventListener("input", updateRangeFill);
		
			return () => {
				audioSource.removeEventListener("loadeddata", updateDuration);
				playerSeekRange.removeEventListener("input", seek);
				playerSeekRange.removeEventListener("input", updateRangeFill);
			};
		}
	}, [audioSource]);

	const isMusicEnd = () => {
		const playBtn = document.querySelector("[data-play-btn]");
		const playerSeekRange = document.querySelector("[data-seek]");

		const playerRunningTime = document.querySelector("[data-running-time");

		if (audioSource.ended) {
			playBtn.classList.remove("active");
			audioSource.currentTime = 0;
			playerSeekRange.value = audioSource.currentTime;
			playerRunningTime.textContent = getTimecode(audioSource.currentTime);
			updateRangeFill();
			nextSong();
		}
	}

	const updateRangeFill = () => {
		const range = document.querySelector("[data-seek]");
		let element = this || range;
		
		const rangeValue = (element.value / element.max) * 100;
		element.nextElementSibling.style.width = `${rangeValue}%`;
	};

	const updateRunningTime = () => {
		const playerSeekRange = document.querySelector("[data-seek]");
		const playerRunningTime = document.querySelector("[data-running-time");

		playerSeekRange.value = audioSource.currentTime;
		playerRunningTime.textContent = getTimecode(audioSource.currentTime);

		updateRangeFill();
		isMusicEnd();
	}

	const getTimecode = (duration) => {
		const minutes = Math.floor(duration / 60);
		const seconds = Math.ceil(duration - minutes * 60);
		const timecode = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
		return timecode;
	};

	const playMusic = () => {
		let playInterval;

		if (audioSource.paused) {
			audioSource.play();				
			togglePlay(true);
			playInterval = setInterval(updateRunningTime, 500);
		} else {
			audioSource.pause();
			togglePlay(false);
			clearInterval(playInterval);
		}
	};

	const togglePlay = (active) => {
		const playBtn = document.querySelector("[data-play-btn]");
		const playSongBtns = document.querySelectorAll("[data-play-song-btn]");

		if (active) {
			playBtn.classList.add("active");
			playSongBtns[lastPlayedMusic].classList.remove("active");
			playSongBtns[currentMusic].classList.add("active");
		} else {
			playBtn.classList.remove("active");
			playSongBtns[currentMusic].classList.remove("active");
		}
	};

	const changePlaylistItem = (index) => {
		setLastPlayedMusic(currentMusic);
		setCurrentMusic(index);

		if (currentMusic === index) {
			setIsPlaying(!isPlaying);
		} else {
			setIsPlaying(true);
		}
	};

	const nextSong = () => {
		setLastPlayedMusic(currentMusic);
		if (currentMusic < musicData.length - 1) {
			setCurrentMusic(currentMusic + 1);
		} else {
			setCurrentMusic(0);
		}
	};

	const prevSong = () => {
		setLastPlayedMusic(currentMusic);
		if (currentMusic > 0) {
			setCurrentMusic(currentMusic - 1);
		} else {
			setCurrentMusic(musicData.length - 1);
		}
	};

	const changeVolume = (e) => {
		const newVolume = parseFloat(e.target.value);
		setVolume(newVolume);
		setMuted(false);

		// Adjust the volume icon based on the volume level
		const volumeBtnIcon = document.querySelector("[data-volume-btn] > .material-symbols-rounded");
		if (newVolume <= 0.1) {
			volumeBtnIcon.textContent = "volume_mute";
		} else if (newVolume <= 0.5) {
			volumeBtnIcon.textContent = "volume_down";
		} else {
			volumeBtnIcon.textContent = "volume_up";
		}

		audioSource.volume = newVolume;
	};

	const muteVolume = () => {
		if (!muted) {
			setMuted(true);
			const volumeBtnIcon = document.querySelector("[data-volume-btn] > .material-symbols-rounded");
			volumeBtnIcon.textContent = "volume_off";
		} else {
			setMuted(false);
			changeVolume({ target: { value: volume } });
		}
	};

	return (
		<div ref={portfolioRef} id="portfolio" className="section bg-warning-200 horde-section-padding2 horde-section-portfolio">
			<div className="container">
				<img src="/images/all-img/red-frame.png" className="red-frame-prices" />
				<div className="horde-section-title">
					<h2>Portfolio</h2>
				</div>
				<div className="playlist-container">
					<div className='mt-3 tracklist-widget-container'>
						<div className='tracklist-container'>
							<div className='tracklist-flex-container'>
								<div className='tracklist-coverart-container'>
									<a title='Play on Spotify' href={SOCIAL_LINKS.spotify} role='button' target='_blank' rel="noreferrer">
										<img src='/images/logo/logo-bull.png' alt='Horde Echo Coverart' />
									</a>
								</div>
								<div className='tracklist-info-container'>
									<div className='tracklist-spotifylogo-container'>
										<div>
											<a title='Play on Spotify' href={SOCIAL_LINKS.spotify} role='button' target='_blank' rel="noreferrer">
												<img src='/images/social2/spotify.png' alt='Listen on Spotify' className='tracklist-spotifylogo' />
											</a>
										</div>
									</div>
									<div className='mb-3 tracklist-titles-container'>
										<div className='tracklist-title'><a href={SOCIAL_LINKS.spotify} target='_blank' rel="noreferrer">Portfolio</a></div>
										<div className='tracklist-subtitle'><a href={SOCIAL_LINKS.spotify} target='_blank' rel="noreferrer">Horde Echo Production</a></div>
									</div>
									{!isMobile && !isTablet && (
										<div className='tracklist-controls-container'>
											<div className="seek-control">
												<div style={{width: '80%'}}>
													<div className="range-wrapper">
														<input type="range" step="1" max="90" value="0" className="range" data-seek readOnly />
														<div className="range-fill" data-range-fill></div>
													</div>

													<div className="duration-label wrapper">
														<span className="label-md" data-running-time>0:00</span>
														<span className="label-md" data-duration>0:00</span>
													</div>
												</div>

												<div className="volume">
													<button className="btn-icon" data-volume-btn onClick={muteVolume}>
														<span className="material-symbols-rounded">volume_up</span>
													</button>

													<div className="range-wrapper">
														<input 
															type="range" 
															step="0.05" 
															max="1" 
															value={volume} 
															className="range" 
															data-volume 
															onChange={changeVolume}
														/>
														<div className="range-fill" style={{ width: `${volume * 100}%` }}></div>
													</div>
												</div>
											</div>

											<div className="player-control wrapper">
												<button className="btn-icon toggle" data-repeat>
													{/* <span className="material-symbols-rounded default-icon">repeat</span>
													<span className="material-symbols-rounded active-icon">repeat_one</span> */}
												</button>

												<button className="btn-icon" data-skip-prev onClick={prevSong}>
													<span className="material-symbols-rounded">skip_previous</span>
												</button>

												<button className="btn-icon play" data-play-btn onClick={playMusic}>
													<span className="material-symbols-rounded default-icon">play_arrow</span>
													<span className="material-symbols-rounded active-icon">pause</span>
												</button>

												<button className="btn-icon" data-skip-next onClick={nextSong}>
													<span className="material-symbols-rounded">skip_next</span>
												</button>

												<button className="btn-icon toggle" data-shuffle>
													{/* <span className="material-symbols-rounded">shuffle</span> */}
												</button>
											</div>
										</div>
									)}

									{isTablet && (
										<div className='tracklist-controls-container'>
											<div className="seek-control">
												<div style={{width: '100%'}}>
													<div className="range-wrapper">
														<input type="range" step="1" max="90" value="0" className="range" data-seek />
														<div className="range-fill" data-range-fill></div>
													</div>

													<div className="duration-label wrapper">
														<span className="label-md" data-running-time>0:00</span>
														<span className="label-md" data-duration>0:00</span>
													</div>
												</div>
											</div>
										</div>
									)}
								</div>
							</div>
							{isMobile && (
								<div className='tracklist-controls-container'>
									<div className="seek-control">
										<div style={{width: '100%'}}>
											<div className="range-wrapper">
												<input type="range" step="1" max="90" value="0" className="range" data-seek />
												<div className="range-fill" data-range-fill></div>
											</div>

											<div className="duration-label wrapper">
												<span className="label-md" data-running-time>0:00</span>
												<span className="label-md" data-duration>0:00</span>
											</div>
										</div>
									</div>

									<div className="player-control wrapper">									
										<button className="btn-icon" data-skip-prev onClick={prevSong}>
											<span className="material-symbols-rounded">skip_previous</span>
										</button>

										<button className="btn-icon play" data-play-btn onClick={playMusic}>
											<span className="material-symbols-rounded default-icon">play_arrow</span>
											<span className="material-symbols-rounded active-icon">pause</span>
										</button>

										<button className="btn-icon" data-skip-next onClick={nextSong}>
											<span className="material-symbols-rounded">skip_next</span>
										</button>

										<div className="volume">
											<button className="btn-icon" data-volume-btn onClick={muteVolume}>
												<span className="material-symbols-rounded">volume_up</span>
											</button>

											<div className="range-wrapper">
												<input 
													type="range" 
													step="0.05" 
													max="1" 
													value={volume} 
													className="range" 
													data-volume 
													onChange={changeVolume}
												/>
												<div className="range-fill" style={{ width: `${volume * 100}%` }}></div>
											</div>
										</div>
									</div>
								</div>
							)}

							{isTablet && (
								<div className="player-control wrapper">											
									<button className="btn-icon" data-skip-prev onClick={prevSong}>
										<span className="material-symbols-rounded">skip_previous</span>
									</button>

									<button className="btn-icon play" data-play-btn onClick={playMusic}>
										<span className="material-symbols-rounded default-icon">play_arrow</span>
										<span className="material-symbols-rounded active-icon">pause</span>
									</button>

									<button className="btn-icon" data-skip-next onClick={nextSong}>
										<span className="material-symbols-rounded">skip_next</span>
									</button>

									<div className="volume">
										<button className="btn-icon" data-volume-btn onClick={muteVolume}>
											<span className="material-symbols-rounded">volume_up</span>
										</button>

										<div className="range-wrapper">
											<input 
												type="range" 
												step="0.05" 
												max="1" 
												value={volume} 
												className="range" 
												data-volume 
												onChange={changeVolume}
											/>
											<div className="range-fill" style={{ width: `${volume * 100}%` }}></div>
										</div>
									</div>
								</div>
							)}
						</div>
						<div className='tracklist-songslist-container'>
							<div className='tracklist-songslist'>
								{musicData.map((music, index) => (
									<div 
										className="song-data-container"
										key={index}
										onClick={() => changePlaylistItem(index)}
									>
										<div className='song-title-container'>
											<div className='player-button'>
												<button 
													className="btn-icon play" 
													data-play-song-btn 
													onClick={() => changePlaylistItem(index)}
												>
													<span className="material-symbols-rounded default-icon">play_arrow</span>
													<span className="material-symbols-rounded active-icon">pause</span>
												</button>
											</div>
											<div className='song-title'>
												<div className='song-title-text'>{music.title}</div>
												<div className='song-artist-text'>{music.artist}</div>
											</div>
										</div>
										<div className='preview-container'>
											<div className='preview-background'>
												<div className='preview-banner'>Preview</div>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
						{isPage && (
							<div className="horde--btn-wrap horde--hero-btn wow fadeInUpX horde--portfolio-page-btn" data-wow-delay="0.20s">
								<a href={PAGE_ROUTES.HOME + '#booking'} className="horde--btn bg-gray active">Book Your Date</a>
							</div>
						)}
					</div>

					<img src="/images/all-img/red-frame.png" className="red-frame-portfolio" />
				</div>
			</div>
		</div>
	);
};

export default Portfolio;