import React from 'react';
import { PAGE_ROUTES } from '../../routes/routes';

const NotFound = () => {
	return (
		<div className="section">
			<div className="container">
				<div className="horde-error-content wow fadeInUpX">
					<h1>404</h1>
					<h2>Oops, this page is not found</h2>
					<p>
						The page you're looking for can't be found. Double-check the URL and try again.
					</p>
					<a className="horde-btn small-btn" href={PAGE_ROUTES.HOME}>
						Go To Homepage
					</a>
				</div>
			</div>
		</div>
	);
};

export default NotFound;