export const musicData = [
    {
        title: "Pulse",
        artist: "GlassFeathers",
        duration: '03:48',
        musicPath: "/music/9_GlassFeathers_Pulse.mp3",
    },
    {
        title: "tchnąwszy życie wiedział",
        artist: "masakra",
        duration: '02:50',
        musicPath: "/music/8_Masakra.mp3",
    },
    {
        title: "Inuk",
        artist: "Anori",
        duration: '05:58',
        musicPath: "/music/7_Anori.mp3",
    },
    {
        title: "Struggling",
        artist: "Carrywhite",
        duration: '04:01',
        musicPath: "/music/6_Carrywhite.mp3",
    },
    {
        title: "bóg chciał żebym zdechł",
        artist: "masakra",
        duration: '02:47',
        musicPath: "/music/5_Masakra.mp3",
    },
    {
        title: "Countdown to Extinction",
        artist: "Vidjigeria, GlassFeathers",
        duration: '02:57',
        musicPath: "/music/4_Countdown_to_Extinction.mp3",
    },
    {
        title: "Baptized",
        artist: "Emberspire",
        duration: '03:47',
        musicPath: "/music/3_Baptized.mp3",
    },
];