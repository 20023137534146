import React from 'react';
import Slider from 'react-slick';

const Equipment = () => {
	const settings = {
		infinite: true,
		slidesToShow: 6,
		slidesToScroll: 1,
		arrows: false,
		dots: false,
		autoplay: true,
		autoplaySpeed: 1500,
		initialSlide: 1,
		responsive: [
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 2,
				},
			},
		],
	};

	return (
		<div id="equipment" className="horde-equip-section">
			<div className="container">
				<div className="horde-client-title text-white">
					<h5>Studio equipment</h5>
				</div>
				<div className="horde-client-slider">
					<Slider {...settings}>
						<div className="horde-brand-logo">
							<img src="/images/equipment/yamaha.png" alt="Yamaha Logo" />
						</div>
						<div className="horde-brand-logo">
							<img src="/images/equipment/UA.png" alt="Universal Audio Logo" />
						</div>
						<div className="horde-brand-logo">
							<img src="/images/equipment/cubase.png" alt="Cubase Logo" />
						</div>
						<div className="horde-brand-logo bigger-logo">
							<img src="/images/equipment/kemper.png" alt="Kemper Profiler Logo" />
						</div>
						<div className="horde-brand-logo">
							<img src="/images/equipment/novation.png" alt="Novation Logo" />
						</div>
						<div className="horde-brand-logo">
							<img src="/images/equipment/fender.png" alt="Fender Logo" />
						</div>
					</Slider>
				</div>
			</div>
		</div>
	);
};

export default Equipment;