import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollToHash = (id) => {
  const elementRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (location.hash === `#${id}` && elementRef.current) {
      elementRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location, id]);

  return elementRef;
};

export default useScrollToHash;