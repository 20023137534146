import React from 'react';
import Portfolio from '../../components/portfolio/portfolio';

const PortfolioPage = () => {

	return (
        <>
            <div className="background"></div>
                <div className="content content-portfolio">
                <Portfolio isPage={true} />
            </div>
        </>
	);
};

export default PortfolioPage;
