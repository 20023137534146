import React, { useState } from 'react';
import { EffectCards } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { TEAM } from '../../constants/common';
import useScrollToHash from '../../hooks/useScrollToHash';
import useMobile from '../../hooks/useMobile';

const About = () => {
	const isMobile = useMobile();
	const aboutRef = useScrollToHash('about');
	const [isModalOpen, setModalOpen] = useState(false);

	const handleModalOpen = () => {
		setModalOpen(true);
	};

	const handleModalClose = () => {
		setModalOpen(false);
	};

	return (
		<div ref={aboutRef} id="about" className="horde--about-section">
			<div className="container">
				<div className="row">
					<div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
						<h2 className="about-header d-none">About Horde Echo</h2>
						<div className="horde--swiper-slider-wrap">
							<div className="horde--swiper-slider">
								<Swiper effect={'cards'} grabCursor={true} modules={[EffectCards]} className="mySwiper">
									{TEAM.map((member, idx) => (
										<SwiperSlide key={idx}>
											<div className="swiper-slide">
												<div className="horde--card-wrap horde--card2">
													<div className="horde--card-thumb">
														<img src={member.image} alt={member.alt_text} />
													</div>
													<div className="horde--card-data">
														<p>
															{member.name}
															{member.code === 'founder' && isMobile && <button className="info-icon" onClick={handleModalOpen}>i</button>}															
														</p>
														<div className="horde--card-footer">
															<div className="horde--card-footer-data">
																<h4>{member.job}</h4>
															</div>
															{member.social_title && member.social_link && (
																<a href={member.social_link} target="_blank" rel="noreferrer">
																	<button
																		className="horde--btn btn-sm horde--tigger dark-btn"
																		type="button"
																	>
																		<img
																			className="red-heart"
																			src="/images/social2/instagram.svg"
																			alt="Instagram icon"
																		/>
																		{member.social_title}
																	</button>
																</a>
															)}
														</div>
													</div>
												</div>
											</div>
										</SwiperSlide>
									))}
								</Swiper>
							</div>
						</div>
					</div>
					<div className="about-full-text col-xl-5 offset-xl-2 col-lg-7 offset-lg-1 col-md-6 col-sm-12 d-flex align-items-center">
						<div className="horde--default-content content-black">
							<h2>About Horde Echo</h2>
							<p>
								The studio was founded in 2017, for some time it was mainly a part-time endeavor alongside another job.
							</p>
							<p>
								Since 2021 I professionally work with metal bands and musicians around the world.
							</p>
							<p className='hide-text'>
								I work closely with each artist to deliver high-quality results that meet their unique needs and vision.
							</p>
							<p>
								With a passion for heavy music, I want to help every artist achieve their desired sound.
							</p>
						</div>
					</div>
				</div>
			</div>
			{isModalOpen && (
				<div className="modal-overlay" onClick={handleModalClose}>
					<div className="modal-content horde--default-content content-black" onClick={(e) => e.stopPropagation()}>
						<button onClick={handleModalClose} className="close-modal-button">&times;</button>
						<p>
							The studio was founded in 2017, for some time it was mainly a part-time endeavor alongside another job.
						</p>
						<p>
							Since 2021 I professionally work with metal bands and musicians around the world.
						</p>
						<p>
							I work closely with each artist to deliver high-quality results that meet their unique needs and vision.
						</p>
						<p>
							With a passion for heavy music, I want to help every artist achieve their desired sound.
						</p>
					</div>
				</div>
			)}
		</div>
	);
};

export default About;