import React from 'react';
import useMobile from '../../hooks/useMobile';
import PricingFlat from './flat';
import PricingSlider from './slider';
import useTablet from '../../hooks/useTablet';
import useScrollToHash from '../../hooks/useScrollToHash';

const Pricing = () => {
	const isMobile = useMobile();
	const isTablet = useTablet();

	const pricesRef = useScrollToHash('prices');

	return (
		<div ref={pricesRef} id="prices">
			{isMobile || isTablet ? <PricingSlider /> : <PricingFlat />}
		</div>
	);
};

export default Pricing;
