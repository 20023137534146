import { useState, useEffect } from 'react';

export default function useTablet() {
	const [isTablet, setIsTablet] = useState(false);

	useEffect(() => {
		function handleResize() {
			setIsTablet(window.innerWidth > 600 && window.innerWidth < 991);
		}

		window.addEventListener('resize', handleResize);
		handleResize();

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return isTablet;
}