import React from 'react';

export default function NavItem({ text, link, red = false, green = false, setIsMobileMenuOpen = () => {} }) {

	return (
		<li className='nav-item'>
			<div className='nav-link-item'>
				<a onClick={() => setIsMobileMenuOpen(false)}
					href={link} 
					className={`hover-underline ${red ? 'red-link red-underline' : ''} ${green ? 'green-link green-underline' : ''}`}>
					{text}
				</a>
			</div>
		</li>
	);
}
