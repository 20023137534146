import React from 'react';
import useMobile from '../../hooks/useMobile';
import useScrollToHash from '../../hooks/useScrollToHash';
import ServicesFlat from './flat';
import ServicesSlider from './slider';

const Services = () => {
	const isMobile = useMobile();

	const servicesRef = useScrollToHash('services');

	return (
		<div ref={servicesRef} id="services">
			{isMobile ? <ServicesSlider /> : <ServicesFlat />}
		</div>
	);
};

export default Services;
