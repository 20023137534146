import React from 'react';
// import { Helmet } from 'react-helmet';

import Hero from '../../components/hero/hero';
import Services from '../../components/services';
import Portfolio from '../../components/portfolio/portfolio';
import About from '../../components/about/about';
import Equipment from '../../components/equipment/equipment';
import Pricing from '../../components/pricing';
import Booking from '../../components/booking/booking';

const Home = () => {
	return (
		<>
			{/* <Helmet>
				<title>Horde Echo Sound Production</title>
				<meta name="description" content="Looking for a mixing/mastering engineer for your metal tracks? Horde Echo is a Ukrainian studio that works with all genres from rock to djent and grindcore. My goal is to make your tracks sound powerful and clear, while keeping the process easy and affordable for you." />
			</Helmet> */}
			<div className="background"></div>
			<div className="content">
				<Hero />
				<Services />
				<Portfolio />
				<Pricing />
				<About />
				<Equipment />
				<Booking />
			</div>
		</>
	);
};

export default Home;
