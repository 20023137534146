import React from 'react';
import Slider from 'react-slick';
import { PRICES } from '../../constants/common';

const PricingSlider = () => {
	const settings = {
		infinite: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		dots: true,
		autoplay: false,
		autoplaySpeed: 0,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
				},
			},
		],
	};

	return (
		<div className="section horde-section-padding2 section-pricing">
			<div className="container">
				<div className="horde-section-title">
					<h2>
						Pricing
					</h2>
				</div>
				<div className="row" id="table-price-value" data-pricing-dynamic data-value-active="standard">
					<Slider {...settings}>
						{PRICES.map((pricing, idx) => (
							<div key={idx} className="col-lg-4 col-md-6">
								<div className={'horde-pricing-wrap wow fadeInUpX active'} data-wow-delay=".10s">
									<div className="horde-pricing-header">
										<h5>{pricing.title}</h5>
										{pricing.description && <h6>{pricing.description}</h6>}
									</div>
									<div className="horde-pricing-price">
										<span className="horde-pricing-currency">$</span>
										<div className="horde-price">{pricing.prices.advanced}</div>
										{pricing.title !== 'Single' && (<span className="horde-pricing-currency"> / per song</span>)} 
									</div>
									<div className="horde-pricing-body">
										<ul>
											<li>
												<img src="/images/all-img/check3.svg" alt="" />
												Mixing & mastering
											</li>
											<li>
											<img src="/images/all-img/check3.svg" alt="" />
												Audio/Vocal Tuning and Editing
											</li>
											<li>
												<img src="/images/all-img/check3.svg" alt="" />
												Guitars Reamping
											</li>
											<li>
												<img src="/images/all-img/check3.svg" alt="" />
												MIDI Humanizing
											</li>
											<li>
												<img src="/images/all-img/check3.svg" alt="" />
												Advanced automation
											</li>
											<li>
												<img src="/images/all-img/check3.svg" alt="" />
												Post Production <br />(Impacts, Drops, FX/Synth/Keys/Strings)
											</li>
											<li>
												<img src="/images/all-img/check3.svg" alt="" />
                                                Songs included: <span className="numbers-span">{pricing.songs_count}</span>
											</li>
											<li style={{ display: 'flex' }}>
												<img src="/images/all-img/check3.svg" alt="" />
												Revisions:
												<span className={typeof(pricing.revisions.advanced) === 'string' ? 'infinite-sign' : 'numbers-span'} dangerouslySetInnerHTML={{ __html: pricing.revisions.advanced }} />
											</li>
											<li>
												<img src="/images/all-img/check3.svg" alt="" />
												Delivery time: <span className="numbers-span">{pricing.delivery_time.advanced}</span>
											</li>
										</ul>
									</div>
								</div>
							</div>
						))}
					</Slider>
				</div>
			</div>
		</div>
	);
};

export default PricingSlider;