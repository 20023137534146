import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';

import NavItem from './navbar/nav-item';
import Navbar from './navbar/navbar';
import useScroll from '../../hooks/useScroll';

import { PAGE_ROUTES } from '../../routes/routes';

export default function Header() {
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

	const handleCloseMobileMenu = () => {
		setIsMobileMenuOpen(false);
	};

	const scroll = useScroll();
	return (
		<header
			className={`site-header site-header--menu-right horde--header-section horde--header-three ${
				scroll ? 'sticky-menu' : ''
			}`}
			id="sticky-menu"
		>
			<div className="container-fluid">
				<nav className="navbar site-navbar">
					<div className="brand-logo">
						<Link to={PAGE_ROUTES.HOME}>
							<img src="/images/logo/logo.png" alt="Horde Echo Sound Production Logo" className="light-version-logo" />
						</Link>
					</div>
					<div className="menu-block-wrapper">
						<div
							className={`menu-overlay ${isMobileMenuOpen ? 'active' : ''}`}
							onClick={handleCloseMobileMenu}
						></div>
						<nav className={`menu-block ${isMobileMenuOpen ? 'active' : ''}`} id="append-menu-header">
							<div className="mobile-menu-head">
								<div className="mobile-menu-close" onClick={handleCloseMobileMenu}>
									&times;
								</div>
							</div>

							<Navbar>
								<NavItem setIsMobileMenuOpen={setIsMobileMenuOpen} text="Services" link={PAGE_ROUTES.HOME + '#services'} />
								<NavItem setIsMobileMenuOpen={setIsMobileMenuOpen} text="Portfolio" link={PAGE_ROUTES.HOME + '#portfolio'} />
								<NavItem setIsMobileMenuOpen={setIsMobileMenuOpen} text="Prices" link={PAGE_ROUTES.HOME + '#prices'} />
								<NavItem setIsMobileMenuOpen={setIsMobileMenuOpen} text="About" link={PAGE_ROUTES.HOME + '#about'} />
								<NavItem setIsMobileMenuOpen={setIsMobileMenuOpen} text="Files Reqs" link={PAGE_ROUTES.REQS} green={true} />
								<NavItem setIsMobileMenuOpen={setIsMobileMenuOpen} text="Book Your Date" link={PAGE_ROUTES.HOME + '#booking'} red={true} />
							</Navbar>
						</nav>
					</div>

					<a href={PAGE_ROUTES.HOME + '#booking'} className='mobile-header-booking d-none'>
						Book Your Date
					</a>
					<div className="mobile-menu-trigger" onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
						<span></span>
					</div>
				</nav>
			</div>
		</header>
	);
}
