import React from 'react';
import { Link } from 'react-router-dom';
import { SOCIAL_LINKS } from '../../constants/common';
import { PAGE_ROUTES } from '../../routes/routes';

export default function Footer() {
	return (
		<footer className="horde--foote2-section">
			<div className="container">

				<div className="horde--footer-middle">
					<div className="row">
						<div className="col-xl-3 col-lg-2">
							<div className="horde--footer-logo">
								<img src="/images/logo/logo-bull.webp" alt="Horde Echo Logo" />
							</div>
						</div>
						<div className="col-xl-6 col-lg-8 footer-menu-flex">
							<div className="horde--footer-menu2">
								<ul>
									<li className='hover-underline'>
										<a href={PAGE_ROUTES.HOME + '#portfolio'}>Portfolio</a>
									</li>
									<li className='hover-underline'>
										<a href={PAGE_ROUTES.HOME + '#prices'}>Prices</a>
									</li>
									<li className='hover-underline'>
										<a href={PAGE_ROUTES.HOME + '#about'}>About</a>
									</li>
									<li className='hover-underline green-underline'>
										<a href={PAGE_ROUTES.REQS} className='green-link'>Files Reqs</a>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-xl-3 col-lg-2 footer-menu-flex">
							<div className="horde--social-icon horde--social-icon2">
								<ul>
									<li>
										<a href={SOCIAL_LINKS.instagram} target="_blank" rel="noreferrer">
											<img src="/images/social2/instagram.svg" alt="Instagram Link" />
										</a>
									</li>
									<li>
										<a href={SOCIAL_LINKS.spotify} target="_blank" rel="noreferrer">
											<img src="/images/social2/spotify.svg" alt="Spotify Link" />
										</a>
									</li>
									<li>
										<a href={SOCIAL_LINKS.soundcloud} target="_blank" rel="noreferrer">
											<img src="/images/social2/soundcloud.svg" alt="Soundcloud Link" />
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="horde--footer-bottom horde--footer-bottom2">
					<p>&copy; 2023-2024, Horde Echo Sound Production <span role="img" aria-label="Ukraine Flag">&#x1F1FA;&#x1F1E6;</span></p>
					{/* <div>lang</div> */}
				</div>
			</div>
		</footer>
	);
}
