import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from '../pages/home';
import FileReqs from '../pages/reqs';
import Layout from '../components/layout';
import NoMatch from '../pages/no-match';
import PortfolioPage from '../pages/portfolio';

export const PAGE_ROUTES = {
	HOME: '/',
	REQS: '/file-reqs',
	PORTFOLIO: '/portfolio',
	CONTACT: '/contact',
};

const AppRoutes = () => {
	return (
		<Routes>
			<Route path="/" element={<Layout />}>
				<Route index element={<Home />} />
				<Route path="file-reqs" element={<FileReqs />} />
				<Route path="portfolio" element={<PortfolioPage />} />
				<Route path="*" element={<NoMatch />} />
			</Route>
		</Routes>
	);
};

export default AppRoutes;
