import React from 'react';
// import { Helmet } from 'react-helmet';

const Rules = () => {

	return (
		<>
			{/* <Helmet>
				<title>Horde Echo - Files Reqs</title>
				<meta name="description" content="Looking for a mixing/mastering engineer for your metal tracks? My name is Viktor, and I'm working with all heavy genres from rock to djent and grindcore. My goal is to make your tracks sound powerful and clear while keeping the process easy and affordable for you." />
			</Helmet> */}
			<div className="horde-breadcrumb-section">
				<div className="container">
					<div className="breadcrumbs">
						<h1 className="wow fadeInUpX" data-wow-delay="0s">Files Reqs</h1>
					</div>
				</div>
				<div className="horde-shape8">
					<img src="/images/all-img/shape7.png" alt="" />
				</div>
				<div className="horde-shape9">
					<img src="/images/all-img/shape8.png" alt="" />
				</div>
			</div>

			<div className="section">
				<div className="container">
					<div className="horde-team-information-wrap">
						<div className="row">
							<div className="col-lg-12">
								<div className="horde-team-information">
									<p>
									<span className='numbered-list'>1.</span>
									All audio files should be as <span className='marker-span'>separate instrument</span> and in <span className='marker-span'>WAV format</span>, <span className='marker-span'>zeroed out</span>, and starting from one bar before the beginning of the composition. Preferably, the sample rate/bit depth should be <span className='marker-span'>48000 Hz/24bit</span>, but 44100 Hz/24bit/16bit is also acceptable. If you are recording your instruments at 44100 Hz/24bit, <span className='marker-span'>keep that rate for everything in your project</span> and export at the same rate. Drums or bass guitar emulated by a plugin should be in MIDI format (ensure MIDI notes are not overlapping). Synthesizers can be in MIDI or WAV, depending on whether you intend to modify their sound.
									</p>
								</div>
								<div className="horde-team-information">
									<p>
									<span className='numbered-list'>2.</span>
									All instruments should be exported <span className='marker-span'>'DRY' without any processing</span>, and routed to the <span className='marker-span'>center MONO</span> channel. If you want to preserve the original tone of your guitar/bass, please send it with the DI signal. If your synthesizers and sound effects are in WAV format, or if you need to keep the spatial sound of recorded instruments (pre-processed ambient guitars etc.), export them in STEREO.
									</p>
								</div>
								<div className="horde-team-information">
									<p>
									<span className='numbered-list'>3.</span>
									Ideally, audio track volume levels <span className='marker-span'>should range from -9 dB, with peaks not exceeding -5 dB</span>. However, levels up to 0 dB are acceptable as long as they do not clip or distort in any way. After exporting, <span className='marker-span'>please check all tracks for artifacts, clipping</span> and other issues.
									</p>
								</div>
								<div className="horde-team-information">
									<p><span className='numbered-list'>4.</span>Audio tracks should be named by instrument. Vocal tracks should be named according to style and role, abbreviated for clarity. For example:</p>
									<ul className='audio-tracks'>
										<li>1. Vocal Main Growl 1</li>
										<li>2. Vocal M SCRM 2</li>
										<li>3. VCL Back FRY 1</li>
										<li>04. VCL Pan Clean L</li>
										<li>05. Guitar dist L</li>
										<li>06. GTR clean</li>
										<li>07. Gtr ambient</li>
									</ul>
								</div>
								<div className="horde-team-information">
									<p><span className='numbered-list'>5.</span>Project materials <span className='marker-span'>may include demos</span> of the songs and a .txt file with:</p>
									<ul>
										<li><span className='marker-span'>Track tempo</span> (indicate timecodes for tempo changes)</li>
										<li>Project sample rate</li>
										<li>Other important project details</li>
									</ul>
									<br />
									<p>If using VST drums, <span className='marker-span'>please include the screenshot of a drum map</span>, or at least mention what plugin was used.</p>
								</div>

								<div className="horde-team-information horde-team-important-details">
									<h2>Important before recording</h2>
									<p>Before recording guitar or bass, <span className='marker-span font-size-big'>replace old strings</span>, check that your instrument <span className='marker-span'>is in tune</span> after every recorded riff (incl. bridge), and set the tone knob to maximum unless creatively justified. Use a string damper, <span className='marker-span'>good cables, and a quality sound card</span>. Proper string tension is crucial for guitars. Prefer the cleanest and most technical player for recording. Avoid using built in features like a compressor from your sound card; <span className='marker-span'>this all are essential for achieving a good sound.</span></p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Rules;
