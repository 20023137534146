import React from 'react';
import { PAGE_ROUTES } from '../../routes/routes';

const Hero = () => {
	return (
		<div id="hero" className="horde--hero-section">
			<div className="container">
				<div className="row">
					<div className="col-lg-7 hero-text-flex">
						<div className="horde--hero-content">
							<h1 className="wow fadeInUpX" data-wow-delay="0s">
								Forging<br /> The Metal Sound
							</h1>
							<p className="wow fadeInUpX" data-wow-delay="0.10s">
								Horde Echo is a Ukrainian studio specializing in mixing/mastering all genres of heavy music, from rock to djent and grindcore.
							</p>
							<p>
								My goal is to make your tracks sound powerful and clear, while keeping the process easy and affordable for you.
							</p>
							<p>
								Join the Horde and bring your vision to life!
							</p>
							<div className="horde--btn-wrap horde--hero-btn wow fadeInUpX" data-wow-delay="0.20s">
								<a href={PAGE_ROUTES.HOME + '#booking'} className="horde--btn bg-gray active">Book Your Date</a>
							</div>
						</div>
					</div>
					<div className="col-lg-5">
						<div className="horde--hero-right">
							<img src="/images/hero/hero-with-circle.png" alt="Green bull head with seven blind eyes and nose ring" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Hero;