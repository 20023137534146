export const musicData = [
  {
    title: "Mindset",
    artist: "Carrywhite",
    duration: "03:58",
    musicPath: "/music/12_Carrywhite_Mindset.mp3",
  },
  {
    title: "Brightless Star",
    artist: "One Is Missing",
    duration: "03:58",
    musicPath: "/music/11_One_Is_Missing_Brightless_Star.mp3",
  },
  {
    title: "Conquer",
    artist: "Carrywhite",
    duration: "03:58",
    musicPath: "/music/10_Carrywhite_Conquer.mp3",
  },
  {
    title: "Pulse",
    artist: "GlassFeathers",
    duration: "03:48",
    musicPath: "/music/9_GlassFeathers_Pulse.mp3",
  },
  {
    title: "tchnąwszy życie wiedział",
    artist: "masakra",
    duration: "02:50",
    musicPath: "/music/8_Masakra.mp3",
  },
  {
    title: "Inuk",
    artist: "Anori",
    duration: "05:58",
    musicPath: "/music/7_Anori.mp3",
  },
  {
    title: "Struggling",
    artist: "Carrywhite",
    duration: "04:01",
    musicPath: "/music/6_Carrywhite.mp3",
  },
  {
    title: "bóg chciał żebym zdechł",
    artist: "masakra",
    duration: "02:47",
    musicPath: "/music/5_Masakra.mp3",
  },
  {
    title: "Countdown to Extinction",
    artist: "Vidjigeria, GlassFeathers",
    duration: "02:57",
    musicPath: "/music/4_Countdown_to_Extinction.mp3",
  },
  {
    title: "Baptized",
    artist: "Emberspire",
    duration: "03:47",
    musicPath: "/music/3_Baptized.mp3",
  },
];
